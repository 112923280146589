import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, filter } from 'rxjs';

export type Theme = 'light' | 'dark' | null;

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  theme: Theme | undefined;
  private renderer: Renderer2;
  isDarkTheme$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  readonly darkThemeUrls: string[] = ['my-revenue'];
  readonly lightThemeUrls: string[] = ['signup', 'invitation', 'driver-onboarding'];

  constructor(rendererFactory: RendererFactory2, @Inject(DOCUMENT) private document: Document, private router: Router) {
    this.renderer = rendererFactory.createRenderer(null, null);
    const localStorageValue = localStorage.getItem('theme');
    const initialTheme: Theme = localStorageValue ? JSON.parse(localStorageValue) : 'light';
    this.setTheme(initialTheme);

    this.switchThemeOnRouteChange();
  }

  private switchThemeOnRouteChange() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event) => {
      const url = (event as NavigationEnd).url;
      if (this.darkThemeUrls.some(u => url.includes(u))) {
        this.setTheme('dark');
      } else if (this.lightThemeUrls.some(u => url.includes(u))) {
        this.setTheme('light');
      }
    });
  }

  setTheme(theme: Theme) {
    this.theme = theme;
    localStorage.setItem('theme', JSON.stringify(theme));
    if (this.theme === 'light') {
      this.renderer.addClass(this.document.body, 'light-mode');
      this.renderer.removeClass(this.document.body, 'dark-mode');
      this.renderer.setStyle(document.body, 'color-scheme', 'light');
    } else {
      this.renderer.addClass(this.document.body, 'dark-mode');
      this.renderer.removeClass(this.document.body, 'light-mode');
      this.renderer.setStyle(document.body, 'color-scheme', 'dark');
    }
    this.isDarkTheme$.next(this.theme === 'dark');
  }

}